import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileProcessService {
  constructor(public httpClient: HttpClient) {}

  uploadFile(uploadUrl: string, file: File) {
    return this.httpClient.put(uploadUrl, file, {});
  }

  downloadFile(downloadUrl: string) {
    return this.httpClient.get(downloadUrl, { responseType: 'text' });
  }
}
