import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Apollo } from 'apollo-angular';
import {
  GetAllJobListingQueryDocument,
  CreateNewJobListingMutationDocument,
  RemoveJobMutationDocument,
  UpdateJobMutationDocument,
  GetAllCandidatesForSpecificJobQueryDocument,
  GetSingleCandidateDataQueryDocument,
  GetCandidateApplyingDataQueryDocument,
  UpdateCandidateApplyingDataMutationDocument,
  CandidateInput,
  VerifyCandidateDataEmailMutationDocument,
  ValidateCandidateMutationDocument,
  CheckCandidateVerifiedQueryDocument,
  RemoveCandidateRepoUrlMutationDocument,
  GenerateJobDescriptionMutationDocument,
  CloneSelectedRepoMutationDocument,
  CloneRepo,
  CandidateAliasesDocument,
  GetAccountDataQueryDocument,
  AccountDataInput,
  UpdateAccountDataMutationDocument,
  GetAccountDataByJobIdQueryDocument,
  GetUploadLogoUrlQueryDocument,
  DownloadLogoFileUrlQueryDocument,
  GetUploadCandidateCvUrlQueryDocument,
  DownloadCandidateCvUrlQueryDocument,
  DownloadCompanyLogoUrlQueryDocument,
  ExtractCandidateDataFromCvQueryDocument,
  JobListingInput,
  ChangeJobStatusMutationDocument,
  GetTechnologiesWithValuesQueryDocument,
  AutoGenerateJobQuestionsQueryDocument,
  JobQuestionsData,
  GetQuestionsForSpecificJobPublicQueryDocument,
  GetQuestionsForSpecificJobQueryDocument,
  SaveCandidateAnswersMutationDocument,
  GetScreeningVideoUploadUrlQueryDocument,
  GetScreeningVideoDownloadUrlQueryDocument,
  GetAllTechnologiesQueryDocument,
  GetTechnologiesForSpecificJobQueryDocument,
  GetJobByIdBasicDocument,
  AddMissingTechnologiesFromRunsQueryDocument,
  ReadyCandidateItemMutationDocument,
  AddCandidateRepoUrlMutationDocument,
  UpdateScreeningExtToCandidateMutationDocument,
  GetApplyingJobDataDocument,
} from '@codenteam/portal/graphql';

@Injectable({
  providedIn: 'root',
})
export class HiringService {
  constructor(private apollo: Apollo) {}

  addMissingTechnologiesFromRun(runIds: string[]) {
    return this.apollo
      .query({
        query: AddMissingTechnologiesFromRunsQueryDocument,
        variables: {
          runs: runIds,
        },
      })
      .pipe(map((response) => response.data.addMissingTechnologiesFromRuns));
  }

  getAllJobListing() {
    return this.apollo
      .query({
        query: GetAllJobListingQueryDocument,
      })
      .pipe(map((response) => response.data?.getAllJobListing));
  }

  createNewJobListing(newJobListing: JobListingInput, jobId?: string) {
    return this.apollo
      .mutate({
        mutation: CreateNewJobListingMutationDocument,
        variables: {
          JobListingInput: newJobListing,
          uuid: jobId,
        },
      })
      .pipe(map((response) => response.data.createNewJobListing));
  }

  removeJobListing(jobId: string) {
    return this.apollo
      .mutate({
        mutation: RemoveJobMutationDocument,
        variables: {
          uuid: jobId,
        },
      })
      .pipe(map((response) => response.data.removeJob));
  }

  getJobById(jobId: string) {
    return this.apollo
      .query({
        query: GetJobByIdBasicDocument,
        variables: {
          uuid: jobId,
        },
      })
      .pipe(map((response) => response.data?.getJobById));
  }

  getJobOffer(jobId: string) {
    return this.apollo
      .query({
        query: GetApplyingJobDataDocument,
        variables: {
          uuid: jobId,
        },
      })
      .pipe(map((response) => response.data?.getJobOffer));
  }

  updateJobData(
    updatedJobData: JobListingInput,
    jobId: string,
    technologyIds?: number[],
    questions?: JobQuestionsData[]
  ) {
    return this.apollo
      .mutate({
        mutation: UpdateJobMutationDocument,
        variables: {
          JobListingInput: updatedJobData,
          uuid: jobId,
          technologyId: technologyIds,
          JobQuestionsInput: questions,
        },
      })
      .pipe(map((response) => response.data.updateJob));
  }

  changeJobStatus(uuid: string, status: string) {
    return this.apollo
      .mutate({
        mutation: ChangeJobStatusMutationDocument,
        variables: {
          uuid,
          status,
        },
      })
      .pipe(map((response) => response.data.changeJobStatus));
  }

  getAllCandidatesForSpecificJob(jobId: string) {
    return this.apollo
      .query({
        query: GetAllCandidatesForSpecificJobQueryDocument,
        variables: {
          jobId,
        },
      })
      .pipe(map((response) => response.data?.getAllCandidatesForSpecificJob));
  }

  getSingleCandidate(jobId: string, candidateId: string) {
    return this.apollo
      .query({
        query: GetSingleCandidateDataQueryDocument,
        variables: {
          jobId,
          candidateId,
        },
      })
      .pipe(map((response) => response.data?.getSingleCandidateData));
  }

  getCandidateData(jobId: string, candidateId: string) {
    return this.apollo
      .query({
        query: GetCandidateApplyingDataQueryDocument,
        variables: {
          jobId,
          candidateId,
        },
      })
      .pipe(map((response) => response.data?.getCandidateApplyingData));
  }

  updateCandidateData(
    jobId: string,
    candidateId: string,
    updatedCandidateData: CandidateInput
  ) {
    return this.apollo
      .mutate({
        mutation: UpdateCandidateApplyingDataMutationDocument,
        variables: {
          jobId,
          candidateId,
          candidateInput: updatedCandidateData,
        },
      })
      .pipe(map((response) => response.data?.updateCandidateApplyingData));
  }

  saveCandidateAnswers(
    jobId: string,
    candidateId: string,
    candidateAnswers: string[]
  ) {
    return this.apollo
      .mutate({
        mutation: SaveCandidateAnswersMutationDocument,
        variables: {
          answers: candidateAnswers,
          candidateId,
          jobId,
        },
      })
      .pipe(map((response) => response.data?.saveCandidateAnswers));
  }

  verifyCandidateEmail(jobId: string, candidateId: string, email: string) {
    return this.apollo
      .mutate({
        mutation: VerifyCandidateDataEmailMutationDocument,
        variables: {
          jobId,
          candidateId,
          email: email,
        },
      })
      .pipe(map((response) => response.data?.verifyCandidateDataEmail));
  }

  submitCandidateApplying(jobId: string, candidateId: string) {
    return this.apollo
      .mutate({
        mutation: ReadyCandidateItemMutationDocument,
        variables: {
          candidateId,
          jobId,
        },
      })
      .pipe(map((response) => response.data?.readyCandidateItem));
  }

  validateCandidateEmail(jobId: string, candidateId: string, token: string) {
    return this.apollo
      .mutate({
        mutation: ValidateCandidateMutationDocument,
        variables: {
          jobId,
          candidateId,
          token,
        },
      })
      .pipe(map((response) => response.data?.validateCandidate));
  }

  checkCandidateVerification(jobId: string, candidateId: string) {
    return this.apollo
      .query({
        query: CheckCandidateVerifiedQueryDocument,
        variables: {
          jobId,
          candidateId,
        },
      })
      .pipe(map((response) => response.data?.checkCandidateVerified));
  }

  removeCandidateRepo(repoId: number, candidateId: string) {
    return this.apollo
      .mutate({
        mutation: RemoveCandidateRepoUrlMutationDocument,
        variables: {
          repoId,
          candidateId,
        },
      })
      .pipe(map((response) => response.data?.removeCandidateRepoUrl));
  }

  generateJobDescription(jobData: JobListingInput) {
    return this.apollo
      .mutate({
        mutation: GenerateJobDescriptionMutationDocument,
        variables: {
          JobListingInput: jobData,
        },
      })
      .pipe(map((response) => response.data?.generateJobDescription));
  }

  cloneSelectedCandidateRepo(
    jobId: string,
    candidateId: string,
    repo: CloneRepo
  ) {
    return this.apollo
      .mutate({
        mutation: CloneSelectedRepoMutationDocument,
        variables: {
          jobId,
          candidateId,
          repo,
        },
      })
      .pipe(map((response) => response.data?.cloneSelectedRepo));
  }

  getCandidateAliases(jobId: string, candidateId: string) {
    return this.apollo
      .query({
        query: CandidateAliasesDocument,
        variables: {
          jobId,
          candidateId,
        },
      })
      .pipe(map((response) => response.data?.getCandidateAliases));
  }

  getTechnologiesForSpecificJob(jobId: string) {
    return this.apollo
      .query({
        query: GetTechnologiesForSpecificJobQueryDocument,
        variables: {
          jobId,
        },
      })
      .pipe(map((response) => response.data?.getTechnologiesForSpecificJob));
  }

  getAccountData() {
    return this.apollo
      .query({
        query: GetAccountDataQueryDocument,
      })
      .pipe(map((response) => response.data.getAccountData));
  }

  updateAccountData(accountData: AccountDataInput) {
    return this.apollo.mutate({
      mutation: UpdateAccountDataMutationDocument,
      variables: {
        accountData,
      },
    });
  }

  getAccountDataByJobId(jobId: string) {
    return this.apollo
      .query({
        query: GetAccountDataByJobIdQueryDocument,
        variables: {
          jobId,
        },
      })
      .pipe(map((response) => response.data.getAccountDataByJobId));
  }

  uploadCompanyLogoUrl(jobLogo?: string, jobId?: string) {
    return this.apollo
      .query({
        query: GetUploadLogoUrlQueryDocument,
        variables: {
          jobLogo,
          jobId,
        },
      })
      .pipe(
        map((res) => {
          return res.data?.getUploadLogoUrl;
        })
      );
  }

  downloadCompanyLogoUrl(jobId: string) {
    return this.apollo
      .query({
        query: DownloadLogoFileUrlQueryDocument,
        variables: {
          jobId,
        },
      })
      .pipe(map((response) => response.data?.downloadLogoFileUrl));
  }

  uploadCandidateCvUrl(
    jobId: string,
    recaptchaToken: string,
    recaptchaAction: string
  ) {
    return this.apollo
      .query({
        query: GetUploadCandidateCvUrlQueryDocument,
        variables: {
          jobId,
          recaptchaToken,
          recaptchaAction,
        },
      })
      .pipe(map((response) => response.data?.getUploadCandidateCvUrl));
  }

  uploadScreeningVideoUrl(candidateId: string, jobId: string) {
    return this.apollo
      .query({
        query: GetScreeningVideoUploadUrlQueryDocument,
        variables: {
          candidateId,
          jobId,
        },
      })
      .pipe(map((response) => response.data?.getScreeningVideoUploadUrl));
  }

  downloadScreeningVideoUrl(candidateId: string, jobId: string) {
    return this.apollo
      .query({
        query: GetScreeningVideoDownloadUrlQueryDocument,
        variables: {
          candidateId,
          jobId,
        },
      })
      .pipe(map((response) => response.data?.getScreeningVideoDownloadUrl));
  }

  downloadCandidateCvUrl(candidateId: string, jobId: string) {
    return this.apollo
      .query({
        query: DownloadCandidateCvUrlQueryDocument,
        variables: {
          candidateId,
          jobId,
        },
      })
      .pipe(map((response) => response.data?.downloadCandidateCvUrl));
  }

  extractCandidateCvData(candidateId: string, jobId: string) {
    return this.apollo
      .query({
        query: ExtractCandidateDataFromCvQueryDocument,
        variables: {
          candidateId,
          jobId,
        },
      })
      .pipe(map((response) => response.data?.extractCandidateDataFromCv));
  }
  extractCvData(candidateId: string, jobId: string) {
    return this.apollo
      .query({
        query: ExtractCandidateDataFromCvQueryDocument,
        variables: {
          candidateId,
          jobId,
        },
      })
      .pipe(map((response) => response.data?.extractCandidateDataFromCv));
  }

  getTechnologiesPerCandidate(candidateId: string) {
    return this.apollo
      .query({
        query: GetTechnologiesWithValuesQueryDocument,
        variables: {
          candidateId,
        },
      })
      .pipe(map((response) => response.data?.getTechnologiesWithValues));
  }

  autoGenerateJobQuestions(technologiesIds: number[]) {
    return this.apollo
      .query({
        query: AutoGenerateJobQuestionsQueryDocument,
        variables: {
          technologiesIds: technologiesIds,
        },
      })
      .pipe(map((response) => response.data?.autoGenerateJobQuestions));
  }

  getJobQuestions(jobId: string) {
    return this.apollo
      .query({
        query: GetQuestionsForSpecificJobPublicQueryDocument,
        variables: {
          jobId,
        },
      })
      .pipe(map((response) => response.data?.getQuestionsForSpecificJobPublic));
  }

  getJobQuestionsForApply(jobId: string) {
    return this.apollo
      .query({
        query: GetQuestionsForSpecificJobQueryDocument,
        variables: {
          jobId,
        },
      })
      .pipe(map((response) => response.data?.getQuestionsForSpecificJob));
  }

  getAllTechnologies() {
    return this.apollo
      .query({
        query: GetAllTechnologiesQueryDocument,
      })
      .pipe(map((response) => response.data?.getAllTechnologies));
  }

  addCandidateRepoUrl(candidateId: string, repoUrl: string) {
    return this.apollo
      .mutate({
        mutation: AddCandidateRepoUrlMutationDocument,
        variables: {
          repoUrl,
          candidateId,
        },
      })
      .pipe(map((response) => response.data.addCandidateRepoUrl));
  }

  updateCandidateScreeningExt(candidateId: string, ext: string) {
    return this.apollo
      .mutate({
        mutation: UpdateScreeningExtToCandidateMutationDocument,
        variables: {
          candidateId,
          ext,
        },
      })
      .pipe(map((response) => response.data.updateScreeningExtToCandidate));
  }
}
