import { Injectable } from '@angular/core';
import { HiringService } from '../hiring-service/hiring-service.service';
import { Observable, combineLatest, map, mergeMap, of, tap } from 'rxjs';
import {
  CandidateInput,
  CloneRepo,
  JobListingInput,
  LogoUrlData,
} from '@codenteam/portal/graphql/generated';
import { FileProcessService } from '../file-process/file-process.service';
import { ApiService } from '../../core/api.service';

@Injectable({
  providedIn: 'root',
})
export class HiringCoreService {
  constructor(
    private hiringService: HiringService,
    private fileProcessService: FileProcessService,
    private apiService: ApiService
  ) {}

  getJob(jobId$: Observable<string>) {
    return jobId$.pipe(
      mergeMap((jobId) => {
        return this.hiringService.getJobById(jobId);
      })
    );
  }

  getJobOffer(jobId$: Observable<string>) {
    return jobId$.pipe(
      mergeMap((jobId) => {
        return this.hiringService.getJobOffer(jobId);
      })
    );
  }

  getCandidates(jobId$: Observable<string>) {
    return jobId$.pipe(
      mergeMap((jobId) => {
        return this.hiringService.getAllCandidatesForSpecificJob(jobId);
      })
    );
  }

  getSingleCandidates(
    jobId$: Observable<string>,
    candidateId$: Observable<string>
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap(([jobId, candidateId]) => {
        return this.hiringService.getSingleCandidate(jobId, candidateId);
      })
    );
  }

  getCandidateApplyingData(
    jobId$: Observable<string>,
    candidateId$: Observable<string>
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap(([jobId, candidateId]) => {
        return this.hiringService.getCandidateData(jobId, candidateId);
      })
    );
  }

  getJobQuestions(jobId$: Observable<string>) {
    return jobId$.pipe(
      mergeMap((jobId) => {
        return this.hiringService.getJobQuestionsForApply(jobId);
      })
    );
  }

  getJobQuestionsForApply(jobId$: Observable<string>) {
    return jobId$.pipe(
      mergeMap((jobId) => {
        return this.hiringService.getJobQuestionsForApply(jobId);
      })
    );
  }

  updateCandidateData(
    jobId$: Observable<string>,
    candidateId$: Observable<string>,
    candidateData: CandidateInput
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.updateCandidateData(
          res[0],
          res[1],
          candidateData
        );
      })
    );
  }

  verifyCandidateEmail(
    jobId$: Observable<string>,
    candidateId$: Observable<string>,
    email: string
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.verifyCandidateEmail(res[0], res[1], email);
      })
    );
  }

  submitCandidateApplying(
    jobId$: Observable<string>,
    candidateId$: Observable<string>
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.submitCandidateApplying(res[0], res[1]);
      })
    );
  }

  validateCandidateEmail(
    jobId$: Observable<string>,
    candidateId$: Observable<string>,
    token: string
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.validateCandidateEmail(res[0], res[1], token);
      })
    );
  }

  checkCandidateVerification(
    jobId$: Observable<string>,
    candidateId$: Observable<string>
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.checkCandidateVerification(res[0], res[1]);
      })
    );
  }

  checkUrlValidity(url: string) {
    return this.apiService.checkUrlValidity(url);
  }

  removeCandidateRepo(repoId: number, candidateId$: Observable<string>) {
    return candidateId$.pipe(
      mergeMap((res) => {
        return this.hiringService.removeCandidateRepo(repoId, res);
      })
    );
  }

  generateJobDescription(jobData: JobListingInput) {
    return this.hiringService.generateJobDescription(jobData);
  }

  cloneSelectedCandidateRepo(
    jobId$: Observable<string>,
    candidateId$: Observable<string>,
    repo: CloneRepo
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.cloneSelectedCandidateRepo(
          res[0],
          res[1],
          repo
        );
      })
    );
  }

  getCandidateAliases(
    jobId$: Observable<string>,
    candidateId$: Observable<string>
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.getCandidateAliases(res[0], res[1]).pipe(
          map((data) => {
            return data;
          })
        );
      })
    );
  }

  saveCandidateAnswers(
    jobId$: Observable<string>,
    candidateId$: Observable<string>,
    answers: string[]
  ) {
    return combineLatest([jobId$, candidateId$]).pipe(
      mergeMap((res) => {
        return this.hiringService
          .saveCandidateAnswers(res[0], res[1], answers)
          .pipe(
            map((data) => {
              return data;
            })
          );
      })
    );
  }

  getLogo(jobId$: Observable<string>): Observable<string> {
    return jobId$.pipe(
      mergeMap((jobId) => {
        return this.hiringService.downloadCompanyLogoUrl(jobId);
      })
    );
  }

  getAccountDataBuJobId(jobId$: Observable<string>) {
    return jobId$.pipe(
      mergeMap((res) => {
        return this.hiringService.getAccountDataByJobId(res);
      })
    );
  }

  uploadLogo(
    file: File,
    jobLogo?: string,
    jobId?: string
  ): Observable<LogoUrlData> {
    return this.hiringService.uploadCompanyLogoUrl(jobLogo, jobId).pipe(
      mergeMap((res) => {
        this.fileProcessService.uploadFile(res.logoUrl, file).subscribe();
        return of(res);
      })
    );
  }

  getCompanyLogo(jobId?: string) {
    return this.apiService.downloadLogoUrl(jobId);
  }

  getLogoPreview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      return reader.result;
    };
  }

  downloadCandidateCv(
    candidateId$: Observable<string>,
    jobId$: Observable<string>
  ) {
    return combineLatest([candidateId$, jobId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.downloadCandidateCvUrl(res[0], res[1]).pipe(
          mergeMap((res) => {
            return window.open(res, '_blank');
          })
        );
      })
    );
  }

  requestUploadUrlForCv(
    jobId$: Observable<string>,
    recaptchaToken?: string,
    recaptchaAction?: string
  ) {
    return jobId$.pipe(
      mergeMap((jobId) => {
        return this.hiringService.uploadCandidateCvUrl(
          jobId,
          recaptchaToken,
          recaptchaAction
        );
      })
    );
  }
  uploadCvToUrl(url: string, file: File) {
    return this.fileProcessService.uploadFile(url, file);
  }

  /**
   *
   * @deprecated Use the recaptcha supported flow instead
   * @param jobId$
   * @param file
   * @returns
   */
  uploadCandidateCv(jobId$: Observable<string>, file: File) {
    return this.requestUploadUrlForCv(jobId$).pipe(
      mergeMap((res) => {
        return this.uploadCvToUrl(res.cvUploadUrl, file).pipe(map(() => res));
      }),
      map((res) => res.candidate)
    );
  }
  // extractCvData(candidateId: number) {
  //   return this.hiringService.extractCandidateCvData(candidateId);
  // }
  /**
   *
   * @deprecated why do we need the jobId again???
   * @param candidateId$
   * @param jobId$
   * @returns
   */
  uploadScreeningVideo(
    jobId$: Observable<string>,
    candidateId$: Observable<string>,
    file: File
  ) {
    return combineLatest([candidateId$, jobId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.uploadScreeningVideoUrl(res[0], res[1]).pipe(
          mergeMap((result) => {
            return this.fileProcessService.uploadFile(result, file);
          }),
          map((r) => {
            return this.hiringService
              .updateCandidateScreeningExt(res[0], 'mp4')
              .subscribe();
          })
        );
      })
    );
  }

  ScreeningVideoDownloadUrl(
    jobId$: Observable<string>,
    candidateId$: Observable<string>
  ) {
    return combineLatest([candidateId$, jobId$]).pipe(
      mergeMap((res) => {
        return this.hiringService
          .downloadScreeningVideoUrl(res[0], res[1])
          .pipe();
      })
    );
  }

  extractCandidateCvData(
    candidateId$: Observable<string>,
    jobId$: Observable<string>
  ) {
    return combineLatest([candidateId$, jobId$]).pipe(
      mergeMap((res) => {
        return this.hiringService.extractCandidateCvData(res[0], res[1]);
      })
    );
  }

  getCandidateTechnologies(candidateId$: Observable<string>) {
    return candidateId$.pipe(
      mergeMap((candidateId) => {
        return this.hiringService.getTechnologiesPerCandidate(candidateId);
      })
    );
  }

  addCandidateRepoUrl(candidateId$: Observable<string>, repoUrl: string) {
    return candidateId$.pipe(
      mergeMap((candidateId) => {
        return this.hiringService.addCandidateRepoUrl(candidateId, repoUrl);
      })
    );
  }
}
